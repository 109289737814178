<template>
  <van-form @submit="onSubmit">
    <van-field name="uploader" label="头像">
      <template #input>
        <van-uploader
          v-model="headImgList"
          :max-count="1"
          :after-read="afterRead"
          multiple
          upload-text="上传头像"
          @delete="afterdelete"
          name="headImg"
        />
      </template>
    </van-field>
    <van-field
      v-model="formData.SupplierName"
      name="SupplierName"
      label="姓名"
      placeholder="请输入姓名"
      :rules="[{ required: true, message: '' }]"
    />

    <van-field
      v-model="formData.Phone"
      name="Phone"
      label="联系电话"
      placeholder="联系电话"
      :rules="[{ required: true, message: '' }]"
    />
    <van-field
      readonly
      clickable
      name="Sex"
      :value="formData.Sex"
      label="性别"
      placeholder="请选择性别"
      @click="showPicker = true"
      right-icon="arrow"
      :rules="[{ required: true, message: '' }]"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>

    <van-field
      readonly
      clickable
      name="Birth"
      :value="formData.Birth"
      label="出生年月"
      placeholder="请选择出生年月"
      @click="showCalendar = true"
      right-icon="arrow"
      :rules="[{ required: true, message: '' }]"
    />
    <van-popup v-model="showCalendar" position="bottom">
      <van-datetime-picker
        v-model="defaultDate"
        type="date"
        title="选择年月日"
        @confirm="onselectBirth"
        :min-date="minDate"
        :max-date="maxDate"
      />
    </van-popup>

    <van-field
      readonly
      clickable
      name="NativePlace"
      :value="formData.NativePlace"
      label="地区选择"
      placeholder="点击选择省市区"
      @click="showArea = true"
      right-icon="arrow"
    />
    <van-popup v-model="showArea" position="bottom">
      <van-area
        :area-list="areaList"
        @confirm="onselectArea"
        @cancel="showArea = false"
      />
    </van-popup>

    <van-field
      v-model="formData.GraduationSchool"
      name="GraduationSchool"
      label="毕业院校"
      placeholder="毕业院校"
    />

    <van-field
      v-model="formData.Major"
      name="Major"
      label="专业"
      placeholder="请输入专业"
    />

    <van-field
      readonly
      clickable
      :value="formData.EducationRecord"
      name="EducationRecord"
      label="学历"
      placeholder="请输入学历"
      @click="showEducation = true"
      right-icon="arrow"
    />
    <van-popup v-model="showEducation" position="bottom">
      <van-picker
        show-toolbar
        :columns="educationColumns"
        @confirm="onselectEducation"
        @cancel="showEducation = false"
      />
    </van-popup>

    <van-field
      v-model="formData.WorkOrganization"
      name="WorkOrganization"
      label="任职机构"
      placeholder="请输入任职机构"
    />

    <van-field
      v-model="formData.Positional"
      name="Positional"
      label="职称 "
      placeholder="请输入职称 "
    />

    <van-field
      v-model="formData.Titles"
      name="Titles"
      label="头衔"
      placeholder="多个头衔请用逗号,隔开"
    />

    <van-field
      v-model="formData.TeacherInfo"
      name="TeacherInfo"
      label="师承"
      placeholder="请输入师承"
    />

    <van-field
      v-model="formData.Remark"
      rows="2"
      autosize
      label="个人简介"
      type="textarea"
      maxlength="200"
      placeholder="个人简介"
      show-word-limit
    />

    <van-field name="uploader" label="代表作品">
      <template #input>
        <van-uploader
          v-model="fileList"
          :max-count="9"
          :after-read="afterRead"
          multiple
          upload-text="添加作品"
          @delete="afterdelete"
          name="Images"
        />
      </template>
    </van-field>
    <div style="margin: 16px">
      <van-button round block type="info" native-type="submit">提交</van-button>
    </div>
  </van-form>
</template>
<script>
import { areaList } from "@vant/area-data";
import { artistRegister, querySupplierByStudentId } from "@/api/art";
import { imagesUploadPic } from "@/api/api";
export default {
  data () {
    return {
      areaList,
      resd: {},
      username: "",
      password: "",
      value: "",
      columns: ["男", "女", "保密"],
      educationColumns: ["本科", "硕士", "博士", "博士后", "其他"],
      showPicker: false,
      showCalendar: false,
      showEducation: false,
      showArea: false,
      minDate: new Date(1921, 0, 1),
      defaultDate: new Date(1970, 0, 1),
      maxDate: new Date(),
      formData: {
        Id: "0",
        EducationRecord: "",
        SupplierName: "",
        Phone: "",
        Birth: "",
        NativePlace: "",
        Major: "",
        WorkOrganization: "",
        GraduationSchool: "",
        Sex: "",
        Positional: "",
        Titles: "",
        TeacherInfo: "",
        HeadImg: "",
        Remark: "",
      },
      headImgList: [],
      imgList: [],
      fileList: [],
      route: {},
      artUserId: "",
    };
  },
  created () { },
  mounted () {
    this.route = this.$route.query;
    this.artUserId = localStorage.getItem("artUserId");
    // console.log(this.route.UserID, '')
    this.queryArtistDetail();
  },
  methods: {
    onConfirm (value) {
      this.formData.Sex = value;
      this.showPicker = false;
    },
    onselectBirth (date) {
      this.formData.Birth = this.formatDate(date);
      this.showCalendar = false;
    },
    onselectEducation (value) {
      this.formData.EducationRecord = value;
      this.showEducation = false;
    },
    onselectArea (values) {
      this.formData.NativePlace = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;
    },
    async onSubmit (values) {
      this.$set(this.formData, "SupplierCategory", 1);
      this.$set(this.formData, "StudentId", this.artUserId);
      this.$set(this.formData, "ArtistWorkImgs", this.imgList.join(","));
      // this.formData.SupplierCategory = 1;
      // this.formData.StudentId = this.route.UserID;
      // this.formData.ArtistWorkImgs = this.imgList.join(",");
      delete this.formData.headImgList;
      const res = await artistRegister(this.formData);
      this.resd = res.data;
      if (res.data.success == true) {
        if (this.formData.Id > 0) {
          this.$message.success("信息修改成功");
        } else {
          this.$message.success("信息录入成功，等待审核");
        }
      } else {
        this.$message.error("网络错误，请稍后重试");
      }
      console.log(res);
    },
    formatDate (date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    async queryArtistDetail () {
      const res = await querySupplierByStudentId(
        "?studentId=" + this.route.UserID
      );
      if (res.data.response != null) {
        this.formData = res.data.response;
        var imgages = res.data.response.ArtistWorkImgs ? res.data.response.ArtistWorkImgs.split(",") : [];
        this.formData.Remark = this.formData.Remark.replace(/<[^>]+>/g, '')
        for (let index = 0; index < imgages.length; index++) {
          this.imgList.push(imgages[index]);
          this.fileList.push({ url: imgages[index] });
        }
        if (
          res.data.response.HeadImg != null &&
          res.data.response.HeadImg != ""
        ) {
          this.headImgList.push({ url: res.data.response.HeadImg });
        }
      }
    },
    async postArtRegister () {
      let parm = {
        courseId: this.courseId,
        studentId: this.route.UserID,
        pageIndex: this.page,
        pageSize: this.pageSize,
        answer: this.answer,
      };
      const res = await artistRegister(parm);
      if (res.success === true) {
        this.list = this.list.concat(res.response.data);
        this.page++;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= res.response.dataCount) {
          this.finished = true;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    afterRead (file, name) {
      file.file.uploadName = name.name;

      this.postImagesUploadPic(file.file);
    },
    // 上传文件
    async postImagesUploadPic (file) {
      const formData = new FormData();
      let newFile = await this.common.compressImg(file); //图片压缩
      formData.append("file", newFile);
      formData.append("progame", "ArtistWork");
      const res = await imagesUploadPic(formData);
      if (res.success == true) {
        let url = this.GLOBAL.hostUrl + res.response;
        if (file.uploadName == "Images") {
          this.imgList.push(url);
        } else {
          this.formData.HeadImg = url;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    afterdelete (file) {
      console.log(file);
      if (file.file == null) {
        var index = this.imgList.indexOf(file.url);
        console.log(index);
        if (index != -1) {
          this.imgList.splice(this.imgList.indexOf(file.url), 1);
        } else {
          this.formData.HeadImg = "";
        }
        console.log(index);
      } else {
        //删除数组中的删除元素
        if (file.uploadName == "Images") {
          this.imgList.splice(this.imgList.indexOf(file.file.serverUrl), 1);
        } else {
          this.formData.HeadImg = "";
        }
      }
    },
  },
};
</script>